import React from "react";
import {NavLink} from "react-router-dom";
import "./assets/scss/theme.scss";
import AuthLayout from "./components/layout/auth-layout";
import {useTranslation} from 'react-i18next';
import {Alternative} from "./components/components/Login/Alternative";

function App() {
    const {t} = useTranslation();

    return <>
        <AuthLayout>
            <NavLink to="/" className="d-md-none d-block text-center mb-4">
                <img src="/img/logo.svg" alt=""/>
            </NavLink>
            <h3 className="fw-bold m-f-36">{t('onboarding.title')}</h3>
            <p className="text-gray mb-4 mt-4">{t('onboarding.sub_title')}</p>
            <NavLink
                to="/signup"
                className="btn btn-icon btn-outline-secondary w-100 fw-bold"
            >
                <img src="/img/icon_phone.svg" alt=""/>
                {t('onboarding.sign_up_option')}
            </NavLink>
            <Alternative />
            <NavLink
                to="/join-without-account"
                className="btn btn-icon btn-outline-secondary w-100 fw-bold"
            >{t('onboarding.sign_up_guest')}</NavLink>
            <p className="text-gray mt-4 mb-0 text-center">
                {t('onboarding.has_account')} <NavLink to="/signin" className="fw-bold">{t('sign_in.title')}</NavLink>
            </p>
        </AuthLayout>
    </>
}

export default App;
