import {NavLink, useNavigate} from "react-router-dom";
import AuthLayout from "../../layout/auth-layout";
import Form from "react-bootstrap/Form";
import {useTranslation} from "react-i18next";
import * as React from "react";
import {Header} from "../../components/Login/Header";
import {Button} from "../../components/Form/Button";

function ResetCode() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);
    const {t} = useTranslation();

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true)
        navigate("/success", {replace: true});
    };
    return <AuthLayout>
        <Header title="reset_code.title" />
        <p className="text-gray mt-5 mb-4">{t('reset_code.sub_title')}</p>
        <Form className="text-center reset-input" onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-3">
                    <Form.Group className="mb-4 code-input" controlId="codeInput1">
                        <Form.Control type="text"/>
                    </Form.Group>
                </div>
                <div className="col-3">
                    <Form.Group className="mb-4 code-input" controlId="codeInput2">
                        <Form.Control type="text"/>
                    </Form.Group>
                </div>
                <div className="col-3">
                    <Form.Group className="mb-4 code-input" controlId="codeInput3">
                        <Form.Control type="text"/>
                    </Form.Group>
                </div>
                <div className="col-3">
                    <Form.Group className="mb-4 code-input" controlId="codeInput4">
                        <Form.Control type="text"/>
                    </Form.Group>
                </div>
            </div>
            <p className="text-center text-gray mb-4">
                {t('reset_code.dont_code')} <NavLink to="/" className="fw-bold">{t('reset_code.resend')}</NavLink>
            </p>
            <Button
                title="reset_code.submit"
                isLoading={isLoading} />
        </Form>
    </AuthLayout>
}

export default ResetCode;
