import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

export function Header({title}) {
    const {t} = useTranslation();

    return <h3 className="d-flex align-items-center justify-content-center fw-bold">
        <div className="d-md-none back-cta">
            <NavLink to="/">
                <img src="/img/icon_chevron_left.svg" alt=""/>
            </NavLink>
        </div>
        {t(title)}
    </h3>
}