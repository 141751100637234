import * as React from "react";
import {useTranslation} from "react-i18next";

export function Alternative() {
    const {t} = useTranslation();

    return <>
        <p className="fw-bold text-gray mt-4 text-center">
            {t('sign_in.alternative')}
        </p>
        <ul className="d-flex gap-3 justify-content-center list-unstyled social-login my-4">
            <li>
                <img src="/img/icon_google.svg" alt=""/>
            </li>
            <li>
                <img src="/img/icon_apple.svg" alt=""/>
            </li>
        </ul>
    </>
}