import {AbstractRepository} from "./AbstractRepository";
import axios from "axios";

class TokenRepository extends AbstractRepository {
    get(email) {
        return axios.get(`/token/${email}`);
    }

    validate(token) {
        return axios.get(`/token/validate/${token}`);
    }

    put(token, data) {
        return axios.put(`/token/${token}`, data);
    }
}

export default new TokenRepository();