import axios from "axios";
import {getToken, logout} from "../components/helpers/auth";

export class AbstractRepository {
    constructor() {
        if (getToken()) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
        }
        axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
        axios.interceptors.response.use(response => {
            return response;
        }, error => {
            const {response} = error
            if (response.config.url !== '/api/login' && response.status === 401) {
                logout()
                window.location.replace('/')
            }
            return Promise.reject(error);
        });
    }
}