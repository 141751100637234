import AuthLayout from "../../layout/auth-layout";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

function RegisterSuccess() {
    const {t} = useTranslation();
    return <AuthLayout>
        <div className="success-wrapper">
            <div>
                <h3 className="fw-bold text-center">{t('guest_success.title')}</h3>
                <NavLink to="/">
                    <img src="/img/logo.svg" alt="" className="d-md-none mb-3 mt-2"/>
                </NavLink>
                <p className="text-gray mb-4">{t('guest_success.sub_title')}</p>
                <p className="mb-4 text-center">
                    <img src="/img/success_infographic.svg" alt=""/>
                </p>
            </div>
            <NavLink to="/signin" className="btn btn-primary text-white w-100">{t('guest_success.submit')}</NavLink>
        </div>
    </AuthLayout>
}

export default RegisterSuccess;
