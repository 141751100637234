import axios from "axios";

const LOCAL_STORAGE_KEY = 'token'

export const login = (token) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, token);

    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const logout = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);

    delete axios.defaults.headers.common["Authorization"];
}

export const getToken = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEY)
}
