import {Spinner} from "react-bootstrap";
import * as React from "react";
import {useTranslation} from "react-i18next";
import ButtonBoostrap from "react-bootstrap/Button";

export function Button({title, isLoading = false}) {
    const {t} = useTranslation();

    return <ButtonBoostrap
        variant="primary"
        type="submit"
        className="text-white w-100"
        disabled={isLoading}
    >
        {isLoading && <Spinner
            as="span"
            animation="border"
            size="m"
            role="status"
            aria-hidden="true"
            className="me-2"
        />}{t(title)}
    </ButtonBoostrap>
}