import AuthLayout from "../../layout/auth-layout";
import Form from "react-bootstrap/Form";
import {Header} from "../../components/Login/Header";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {Button} from "../../components/Form/Button";
import TokenRepository from "../../../repositories/TokenRepository";
import {toastMessage} from "../../helpers/toast";

function ForgetPassword() {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState("")

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true)
        try {
            await TokenRepository.get(email);
            toastMessage(t('forget_password.message'))
        } catch (e) {

        } finally {
            setIsLoading(false)
        }
    };
    return <AuthLayout>
        <Header title="forget_password.title" />
        <p className="text-gray mt-5 mb-4">{t('forget_password.sub_title')}</p>
        <Form className="text-start" onSubmit={handleSubmit}>
            <div className="d-flex gap-3">
                {/*<Form.Group className="mb-4">*/}
                {/*    <Form.Label className="fw-bold">Prefix</Form.Label>*/}
                {/*    <Form.Select className="w-100px">*/}
                {/*        <option>+01</option>*/}
                {/*        <option>+01</option>*/}
                {/*        <option>+32</option>*/}
                {/*        <option>+01</option>*/}
                {/*        <option>+01</option>*/}
                {/*    </Form.Select>*/}
                {/*</Form.Group>*/}
                <Form.Group className="flex-grow-1 mb-4" controlId="phoneNumber">
                    <Form.Label className="fw-bold">{t('form.phone.label')}</Form.Label>
                    <div className="input-icon">
                        <img
                            src="/img/icon_phone_gray.svg"
                            alt=""
                            className="icon-left"
                        />
                        <Form.Control
                            type="text"
                            placeholder={t('form.phone.placeholder')}
                            className="icon-single"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </div>
                </Form.Group>
            </div>
            <Button
                title="forget_password.submit"
                isLoading={isLoading} />
        </Form>
    </AuthLayout>
}

export default ForgetPassword;
