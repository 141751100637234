import {NavLink} from "react-router-dom";
import {logout} from "../helpers/auth";
import {ToastContainer} from "react-toastify";
import * as React from "react";
import "react-toastify/dist/ReactToastify.min.css";

function AuthLayout({children}) {
    logout();

    return <div className="container-fluid">
        <div className="row align-items-md-center justify-content-center auth-wrapper">
            <div className="text-md-center">
                <NavLink to="/" className="d-md-block d-none">
                    <img src="/img/logo.svg" alt=""/>
                </NavLink>
                <div className="auth-box">
                    <ToastContainer/>
                    <main>{children}</main>
                </div>
            </div>
        </div>
    </div>
}

export default AuthLayout;
